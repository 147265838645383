<template>
  <v-card
    class="mt-0 mb-0"
    elevation="1"
    height="150"
    :style="$vuetify.breakpoint.smAndDown ? 'border-radius: 8px; padding: 5px; display: flex; align-items: center;' : 'border-radius: 8px; padding: 10px; display: flex; align-items: center;'"
  >
    <v-row style="height: 150px; width: 100%;">
      <v-col
        cols="2"
        md="2"
      >
        <div style="height: 100%; display: flex; flex-direction: column">
          <v-img
            contain
            max-height="130"
            max-width="500"
            :src="produto.imagens[0]"
          />
        </div>
      </v-col>
      <v-col
        cols="10"
        md="10"
      >
        <v-row>
          <v-col
            cols="10"
          >
            <div>
              <div
                :title="produto.nome"
                :style="$vuetify.breakpoint.smAndDown ? 'font-size: 14px; color: #183B94;font-weight: 600; width: 100%; min-width: 30px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;' : 'font-size: 18px; color: #183B94;font-weight: 600; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'"
              >
                {{ produto.nome_produto }}
              </div>
              <div
                :style="$vuetify.breakpoint.smAndDown ? 'font-size: 12px; font-weight: 600;' : 'font-size: 14px; font-weight: 600;'"
              >
                {{ produto.marca }}
              </div>
            </div>
          </v-col>
          <v-col
            v-if="(produto.status === 'Aguardando Cotação' || produto.status === 'Contraproposta Produtor' || produto.status === 'Em Análise') && this.$store.state.user.roles[0].slug === 'tecnico' || this.$store.state.user.roles[0].slug === 'gestor'"
            cols="2"
            class="d-flex justify-end"
          >
            <v-icon color="red">
              mdi-trash-can
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="pt-0 pb-0"
          >
            <v-row v-if="$vuetify.breakpoint.smAndDown">
              <v-col
                cols="6"
                class="pt-1"
              >
                <v-row>
                  <v-col cols="12">
                    <div :style="$vuetify.breakpoint.smAndDown ? 'font-size: 10px; font-weight: 600;' : 'font-size: 12px; font-weight: 600;'">
                      Quantidade
                    </div>
                    <div
                      :style="$vuetify.breakpoint.smAndDown ? 'font-size: 10px;' : 'font-size: 12px;'"
                    >
                      {{ produto.quantidade }} {{ (parseInt(produto.quantidade) > 1 ? " unidades" : " unidade") }} {{ produto.unidade_medida }}
                    </div>
                  </v-col>
                  <v-col
                    v-if="produto.observacao"
                    class="pr-0"
                    cols="12"
                  >
                    <div :style="$vuetify.breakpoint.smAndDown ? 'font-size: 10px; font-weight: 600;' : 'font-size: 12px; font-weight: 600;'">
                      Informação complementar
                    </div>
                    <div
                      :style="$vuetify.breakpoint.smAndDown ? 'font-size: 10px;' : 'font-size: 12px; '"
                    >
                      {{ produto.observacao }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="6"
                class="pt-0 d-flex flex-column align-end justify-end"
              >
                <div v-if="produto.valor_unitario > 0">
                  <div style="font-size: 10px; font-weight: 600; text-align: right;">
                    Valor Unitário
                  </div>
                  <div
                    style="font-size: 10px; text-align: right;"
                  >
                    {{ 'R$ ' + currencyFormatter( produto.valor_unitario ) }}
                  </div>
                </div>
                <div v-if="produto.valor_desconto > 0">
                  <div
                    style="font-size: 10px; font-weight: 600; margin-right: 4px; text-align: right;"
                  >
                    Desconto
                  </div>
                  <div
                    style="font-size: 10px;margin-right: 4px; text-align: right;"
                  >
                    {{ 'R$ ' + currencyFormatter( produto.valor_desconto ) }}
                  </div>
                </div>
                <div v-if="produto.valor_unitario > 0">
                  <div
                    style="font-size: 10px; color: #143693; font-weight: 600; margin-right: 4px; text-align: right;"
                  >
                    Valor Subtotal
                  </div>
                  <div
                    style="font-size: 10px; font-weight: 600; margin-right: 4px; text-align: right;"
                  >
                    {{ 'R$ ' + currencyFormatter( produto.valor_subtotal ) }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col
                cols="12"
                class="pt-1"
              >
                <div :style="$vuetify.breakpoint.smAndDown ? 'font-size: 10px; font-weight: 600;' : 'font-size: 12px; font-weight: 600;'">
                  Quantidade
                </div>
                <div
                  :style="$vuetify.breakpoint.smAndDown ? 'font-size: 10px;' : 'font-size: 12px;'"
                >
                  {{ produto.quantidade }} {{ (parseInt(produto.quantidade) > 1 ? " unidades" : " unidade") }} {{ produto.unidade_medida }}
                </div>
              </v-col>
              <v-col
                v-if="produto.observacao"
                class="pt-0"
                cols="6"
              >
                <div style="font-size: 12px; font-weight: 600;">
                  Informação complementar
                </div>
                <div
                  style="font-size: 12px"
                >
                  {{ produto.observacao }}
                </div>
              </v-col>
              <v-col
                v-if="produto.valor_unitario > 0"
                cols="2"
                class="pr-0 pt-0"
              >
                <div style="font-size: 12px; font-weight: 600;">
                  Valor Unitário
                </div>
                <div
                  style="font-size: 12px; "
                >
                  {{ 'R$ ' + currencyFormatter( produto.valor_unitario ) }}
                </div>
              </v-col>
              <v-col
                v-if="produto.valor_subtotal > 0"
                cols="2"
                class="pr-0 pt-0"
              >
                <div
                  style="font-size: 12px; font-weight: 600;"
                >
                  Subtotal
                </div>
                <div
                  style="font-size: 12px;"
                >
                  {{ 'R$ ' + currencyFormatter( produto.valor_subtotal ) }}
                </div>
              </v-col>
              <v-col
                v-if="produto.valor_desconto > 0"
                cols="2"
                class="pr-0 pt-0"
              >
                <div
                  style="font-size: 12px; font-weight: 600;"
                >
                  Desconto
                </div>
                <div
                  style="font-size: 12px;"
                >
                  {{ produto.valor_desconto > 0 ? 'R$ ' + currencyFormatter( produto.valor_desconto ) : 'R$ 0,00' }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

    <script>
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    props: {
      produto: {
        type: Object,
        default: () => (
          {
            id: 'fa6d6298-60bf-4509-9dbb-79d1c51a3d90',
            nome: 'Excede (CCFA)',
            marca: 'Zoetis',
            descricao: 'EXCEDE (CCFA) Suspensão Estéril – 200 mg/mL é uma formulação pronta para uso que contém ácido livre cristalino de ceftiofur, um antibiótico com largo espectro de ação, pertencente ao grupo das cefalosporinas, ativo contra bactérias Gram positivas e Gram negativas, incluindo cepas produtoras de β-lactamase. Como outras cefalosporinas, ceftiofur é bactericida in vitro, devido a sua ação de inibição da síntese da parede bacteriana.',
            imagem: 'http://testelactpro.esteiogestao.com.br/produtos/24b6b33a-3d1f-409e-939a-ab81bed0929f.0.png',
          }
        ),
      },
    },
    data () {
      return {
        descLength: 150,
        currencyFormatter,
      }
    },
    methods: {
    },
  }
    </script>
