<template>
  <base-loading v-if="!ready" />
  <div
    v-else
    class="content"
    style="padding: 20px"
  >
    <v-chip
      :color="statusCotacaoColor(cotacao.status)"
      label
      text-color="white"
      style="display: flex; justify-content: center;"
    >
      {{ cotacao.status }}
    </v-chip>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="4"
        xl="4"
        class="mt-4"
      >
        <v-card
          class="mt-0 pb-2"
          elevation="1"
          style="border-radius: 8px;"
        >
          <v-card-title class="d-flex justify-space-between pb-0">
            <h3 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Cotação
            </h3>
            <h3 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              #{{ cotacao.cotacao_id }}
            </h3>
            <v-btn
              color="#183B94"
              x-small
              class="text-none text-white"
              style="color: white; border-radius: 8px;"
              @click="generatePDF"
            >
              <v-icon
                small
                class="mr-1"
              >
                mdi-printer
              </v-icon>
              Imprimir
            </v-btn>
          </v-card-title>
          <v-card-title class="d-flex justify-space-between pb-0 pt-0">
            <h4 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Data e hora:
            </h4>
            <h4 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              {{ moment(cotacao.created_at).format('DD/MM/YYYY - HH:mm:ss') }}
            </h4>
          </v-card-title>
          <v-divider class="ml-4 mr-4 mt-2 mb-2" />
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Produtor:</h4>
            <h4> {{ cotacao.nome_produtor }}</h4>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Fazenda:</h4>
            <h4>{{ cotacao.nome_fazenda }}</h4>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Endereço:</h4>
            <h4>
              {{ cotacao.bairro_fazenda ? cotacao.bairro_fazenda : 'S/Bairro' }}
              <!-- {{ pedido.estado_fazenda }}
              {{ pedido.cidade_fazenda }} -->
            </h4>
          </v-card-title>
        </v-card>
        <v-card
          class="mt-0 pb-2 pl-2 pr-2"
          elevation="1"
          style="border-radius: 8px;"
        >
          <v-card-title>
            <h2 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Informações adicionais
            </h2>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Data ideal para entrega:</h4>
            <h4>{{ cotacao.data_prevista_entrega === null ? "Data não inserida" : moment(cotacao.data_prevista_entrega).format('DD/MM/YYYY') }}</h4>
          </v-card-title>
          <v-card-title
            v-if="this.$store.state.user.roles[0].slug === 'tecnico' || this.$store.state.user.roles[0].slug === 'gestor'"
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Tipo de atendimento:</h4>
            <h4>{{ cotacao.tipo_atendimento }}</h4>
          </v-card-title>
          <div v-if="cotacao.observacao">
            <v-divider />
            <v-card-title
              style="font-size: 12px; font-weight: 700;"
              class="d-flex justify-space-between pb-0 pt-0"
            >
              <h4>Observação:</h4>
              <h4 style="font-weight: 400">
                {{ cotacao.observacao }}
              </h4>
            </v-card-title>
          </div>
        </v-card>
        <v-card
          v-if="cotacao.contraproposta"
          class="mt-0 pb-2 pl-2 pr-2"
          elevation="1"
          style="border-radius: 8px;"
        >
          <v-card-title>
            <h2 style="font-size: 14px; font-weight: 700; color: red; ">
              Contraproposta enviada pelo Produtor
            </h2>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4 style="font-weight: 400">
              {{ cotacao.contraproposta }}
            </h4>
          </v-card-title>
        </v-card>
        <v-card
          v-if="cotacao.resposta"
          class="mt-0 pb-2 pl-2 pr-2"
          elevation="1"
          style="border-radius: 8px;"
        >
          <v-card-title>
            <h2 style="font-size: 14px; font-weight: 700; color: #68BD52;">
              Resposta da Contraproposta enviada pelo Técnico
            </h2>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4 style="font-weight: 400">
              {{ cotacao.resposta }}
            </h4>
          </v-card-title>
        </v-card>
        <v-card
          v-if="cotacao.valor_subtotal > 0"
          class="mt-0 pb-2 pl-2 pr-2"
          elevation="1"
          style="border-radius: 8px;"
        >
          <v-card-title>
            <h2 style="font-size: 14px; font-weight: 700; color: #183B94;">
              Detalhes do Pagamento do Pedido
            </h2>
          </v-card-title>
          <v-card-title
            style="font-size: 12px;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <div>Subtotal do produtos</div>
            <div>R$ {{ currencyFormatter(cotacao.valor_subtotal) }}</div>
          </v-card-title>
          <v-card-title
            style="font-size: 12px;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <div>Descontos</div>
            <div> R$ {{ currencyFormatter(cotacao.valor_descontos) }}</div>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Total do pedido</h4>
            <h4>
              R$ {{ currencyFormatter( cotacao.valor_total ) }}
            </h4>
          </v-card-title>

          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Forma de pagamento</h4>
            <h4>
              {{ cotacao.parcelas }} x de R$ {{ currencyFormatter(cotacao.valor_total / cotacao.parcelas) }}
            </h4>
          </v-card-title>
        </v-card>
        <v-card
          v-if="cotacao.signature_id"
          class="mt-0 pb-2"
          elevation="1"
          style="border-radius: 8px;"
        >
          <v-card-title>
            <h2 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Assinatura do produtor
            </h2>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>{{ cotacao.signature_id }}</h4>
          </v-card-title>
        </v-card>
        <div
          v-if="$vuetify.breakpoint.smAndUp && this.$store.state.user.roles[0].slug === 'produtor'"
          style="display: flex; flex-direction: column; gap: 10px"
        >
          <v-btn
            v-if="(cotacao.status === 'Aguardando Aceite Produtor' && cotacao.num_proposta < 2) && checkDateCotacao(cotacao.created_at)"
            block
            small
            color="#183B94"
            height="35px"
            class="text-none text-white"
            style="border-radius: 8px; color: white"
          >
            <v-icon>mdi-file-document-edit-outline</v-icon>
            Enviar Contraproposta
          </v-btn>
          <v-btn
            v-if="cotacao.status === 'Aguardando Aceite Produtor' && checkDateCotacao(cotacao.created_at)"
            color="success"
            block
            small
            height="35px"
            class="text-none text-white"
            style="border-radius: 8px;"
          >
            <v-icon>mdi-check-bold</v-icon>
            Confirmar Pedido
          </v-btn>
          <v-btn
            v-if="(cotacao.status === 'Aguardando Cotação' || cotacao.status === 'Aguardando Aceite Produtor') && checkDateCotacao(cotacao.created_at)"
            color="red darken-3"
            block
            small
            height="35px"
            class="text-none text-white"
            style="border-radius: 8px; color: white;"
          >
            <v-icon>
              mdi-trash-can
            </v-icon>
            Solicitar Cancelamento
          </v-btn>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="8"
        xl="8"
        :class="$vuetify.breakpoint.lgAndUp ? 'py-0 mt-7 mb-16' : 'py-0 mt-0 mb-16'"
      >
        <v-card
          v-if="$vuetify.breakpoint.smAndUp"
          class="my-3 py-2 mt-0"
          elevation="1"
          style="border-radius: 8px;"
        >
          <v-card-title>
            <h2
              style="font-size: 18px; font-weight: 700;"
            >
              Produtos
            </h2>
          </v-card-title>
          <div style="min-height: 687px; padding: 16px">
            <v-data-iterator
              :items="cotacao.itens"
              :items-per-page.sync="itemsPerPage"
              :page.sync="page"
              :sort-desc="sortDesc"
              hide-default-footer
            >
              <template v-slot:default="props">
                <card-cotacao
                  v-for="item in props.items"
                  :key="item.id"
                  class="mb-5"
                  :produto="item"
                />
              </template>
            </v-data-iterator>
          </div>
        </v-card>
        <div v-else>
          <v-row>
            <v-col
              cols="12"
              style="min-height: 100px;"
            >
              <card-cotacao
                v-for="item in cotacao.itens"
                :key="item.id"
                class="mb-4"
                :produto="item"
              />
            </v-col>
            <v-col
              v-if="this.$store.state.user.roles[0].slug === 'produtor'"
              cols="12"
              style="display: flex; flex-direction: column; gap: 10px"
            >
              <v-btn
                v-if="(cotacao.status === 'Aguardando Aceite Produtor' && cotacao.num_proposta < 2) && checkDateCotacao(cotacao.created_at)"
                block
                small
                color="#183B94"
                height="35px"
                class="text-none text-white"
                style="border-radius: 8px; color: white;"
              >
                <v-icon>mdi-file-document-edit-outline</v-icon>
                Enviar Contraproposta
              </v-btn>
              <v-btn
                v-if="cotacao.status === 'Aguardando Aceite Produtor' && checkDateCotacao(cotacao.created_at)"
                color="success"
                block
                small
                height="35px"
                class="text-none text-white"
                style="border-radius: 8px;"
              >
                <v-icon>mdi-check-bold</v-icon>
                Confirmar Pedido
              </v-btn>
              <v-btn
                v-if="(cotacao.status === 'Aguardando Cotação' || cotacao.status === 'Aguardando Aceite Produtor') && checkDateCotacao(cotacao.created_at)"
                color="red darken-3"
                block
                small
                height="35px"
                class="text-none text-white"
                style="border-radius: 8px; color: white;"
              >
                <v-icon>
                  mdi-trash-can
                </v-icon>
                Solicitar Cancelamento
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <iframe
        v-if="pdfUrl"
        :src="pdfUrl"
        width="100%"
        height="500px"
      />
    </v-row>
  </div>
</template>
<script>
  import { DADOS_COTACAO } from '@/store/modules/cotacoes'
  import { mapState, mapActions } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'
  import { checkDateCotacao, statusCotacaoColor } from '../../utils/constants'
  import CardCotacao from './CardCotacao.vue'
  import JsPDF from 'jspdf'

  export default {
    components: {
      CardCotacao,
    },
    data () {
      return {
        page: 1,
        itemsPerPage: 4,
        sortDesc: false,
        currencyFormatter,
        checkDateCotacao,
        statusCotacaoColor,
        id: null,
        pdfUrl: null,
      }
    },
    computed: {
      ...mapState('cotacoes', ['ready', 'cotacao']),
      numberOfPages () {
        return Math.ceil(this.cotacao.itens.length / this.itemsPerPage)
      },
    },
    created () {
      const id = this.$route.query.id
      this.DADOS_COTACAO(id)
    },
    methods: {
      ...mapActions('cotacoes', [DADOS_COTACAO]),
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      generatePDF () {
        let y = 10
        const dateNow = (new Date()).toLocaleString()
        var totalPages = '{total_pages_count_string}'

        function addFooter (pageNumber, doc, totalPages) {
          const docWidth = doc.internal.pageSize.width
          const docHeight = doc.internal.pageSize.height

          doc.setFontSize(7)
          doc.text(dateNow, 10 - 5, docHeight - 10)
          doc.text('https://app.clubedoprodutor.com.br', docWidth / 2, docHeight - 10, { align: 'center' })
          doc.text('Página ' + pageNumber + ' / ' + totalPages, docWidth + 17, docHeight - 10, { align: 'right' })
        }

        const doc = new JsPDF('portrait')

        doc.setFontSize(20)
        doc.setTextColor('#183B94')
        doc.setFont('helvetica', 'bold')
        doc.text(`Pedido   #${this.cotacao.cotacao_id}`, 10, y)
        doc.setFontSize(14)
        doc.text(`Data e hora: ${this.moment(this.cotacao.created_at).format('DD/MM/YYYY - HH:mm:ss')}`, 10, y += 8)
        doc.text(`Status: ${this.cotacao.status}`, 10, y += 8)
        doc.addImage(window.location.origin + '/img/logo_branca.png', 'png', 140, 5, 60, 22)
        doc.setTextColor('black')

        // DADOS PRODUTOR
        doc.text('Dados do produtor', 10, y += 20)
        doc.line(10, y + 4, 200, y + 4)

        doc.autoTable({
          theme: 'plain',
          startY: y + 5,
          margin: { top: 43, left: 8, right: 10, bottom: 10 },
          body: [
            [{ content: 'Produtor:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, `${this.cotacao.nome_produtor}`],
            [{ content: 'Fazenda:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, `${this.cotacao.nome_fazenda}`],
            [{ content: 'Endereço:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, `${this.cotacao.bairro_fazenda}`],
          ],
          rowPageBreak: 'auto',
        })

        if (this.cotacao.contraproposta || this.cotacao.resposta) {
          doc.text('Informações Adicionais', 10, y += 40)
          doc.line(10, y + 4, 200, y + 4)
          doc.autoTable({
            theme: 'plain',
            startY: y + 5,
            margin: { top: 43, left: 8, right: 10, bottom: 10 },
            body: [
              [{ content: 'Contraproposta enviada pelo Produtor:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, `${this.cotacao.contraproposta ? this.cotacao.contraproposta : ''}`],
              [{ content: 'Resposta da Contraproposta enviada pelo Técnico:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, `${this.cotacao.resposta ? this.cotacao.resposta : ''}`],
            ],
            rowPageBreak: 'auto',
          })
        }

        // DETALHES PAGAMENTO
        if (this.cotacao.valor_total > 0) {
          doc.setFont('helvetica', 'bold')
          doc.setFontSize(14)
          doc.text('Detalhes do pagamento', 10, y += 40)
          doc.line(10, y + 4, 200, y + 4)
          doc.autoTable({
            theme: 'plain',
            startY: y + 5,
            margin: { top: 43, left: 8, right: 10, bottom: 10 },
            body: [
              [{ content: 'Subtotal do produtos:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, `R$ ${currencyFormatter(this.cotacao.valor_subtotal)}`],
              [{ content: 'Descontos:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, `R$ ${currencyFormatter(this.cotacao.valor_descontos)}`],
              [{ content: 'Total:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, `R$ ${currencyFormatter(this.cotacao.valor_total)}`],
              [{ content: 'Forma de pagamento:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, `${this.cotacao.parcelas} x de R$ ${currencyFormatter(this.cotacao.valor_total / this.cotacao.parcelas)}`],
            ],
            rowPageBreak: 'auto',
          })
        }

        // observação
        if (this.cotacao.observacao) {
          doc.text('Observação', 10, y += 50)
          doc.line(10, y + 4, 200, y + 4)
          doc.autoTable({
            theme: 'plain',
            startY: y + 5,
            margin: { top: 43, left: 8, right: 10, bottom: 10 },
            body: [
              [{ content: `${this.cotacao.observacao === null ? '' : this.cotacao.observacao}`, styles: { halign: 'left' } }],
            ],
            rowPageBreak: 'auto',
          })
        }

        // produtos
        doc.setFont('helvetica', 'bold')
        doc.text('Produtos', 10, y += 40)
        doc.line(10, y + 4, 200, y + 4)
        doc.setFont('helvetica', 'normal')
        if (this.cotacao.valor_total > 0) {
          doc.autoTable({
            theme: 'plain',
            startY: y + 5,
            rowPageBreak: 'auto',
            margin: { top: 10, left: 8, right: 10, bottom: 10 },
            columns: [
              { title: 'Produto', dataKey: 'nome_produto' },
              { title: 'Marca', dataKey: 'marca' },
              { title: 'Qtd.', dataKey: 'quantidade' },
              { title: 'Valor Un.', dataKey: 'valor_unitario' },
              { title: 'Subtotal', dataKey: 'valor_subtotal' },
              { title: 'Desconto', dataKey: 'valor_desconto' },
              { title: 'Total', dataKey: 'valor_total' },
            ],
            body: this.cotacao.itens,
            columnStyles: { valor_unitario: { minCellWidth: 25 }, valor_total: { minCellWidth: 25 }, valor_desconto: { minCellWidth: 25 }, valor_subtotal: { minCellWidth: 25 } },
            didParseCell: (data) => {
              const trocarPonto = data.column.dataKey === 'valor_unitario' || data.column.dataKey === 'valor_subtotal' || data.column.dataKey === 'valor_desconto' || data.column.dataKey === 'valor_total'
              if (trocarPonto) {
                data.cell.styles.halign = 'right'
                if (data.row.section === 'body') {
                  if (data.cell.text[0] !== '') {
                    data.cell.text = `R$ ${currencyFormatter(data.cell.text)}`
                  }
                }
              }
              if (data.column.dataKey === 'quantidade') {
                data.cell.styles.halign = 'right'
              }
            },
            didDrawPage: (data) => {
              addFooter(data.pageNumber, doc, totalPages)
            },
          })
        } else {
          doc.autoTable({
            theme: 'plain',
            startY: y + 5,
            rowPageBreak: 'auto',
            margin: { top: 10, left: 8, right: 10, bottom: 10 },
            columns: [
              { title: 'Produto', dataKey: 'nome_produto' },
              { title: 'Marca', dataKey: 'marca' },
              { title: 'Qtd.', dataKey: 'quantidade' },
            ],
            body: this.cotacao.itens,
            columnStyles: { valor_unitario: { minCellWidth: 30 }, valor_total: { minCellWidth: 30 }, valor_desconto: { minCellWidth: 30 }, valor_subtotal: { minCellWidth: 30 } },
            didParseCell: (data) => {
              if (data.column.dataKey === 'quantidade') {
                data.cell.styles.halign = 'right'
              }
            },
            didDrawPage: (data) => {
              addFooter(data.pageNumber, doc, totalPages)
            },
          })
        }

        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPages)
        }

        doc.save('cotacao_' + this.cotacao.cotacao_id + '.pdf')
      },
    },
  }
</script>
